<template>
  <v-card
    elevation="6"
    class="my-card"
    :to="{
      name: 'opportunity',
      params: { id: info.Opportunities.Id.toString() }
    }"
  >
    <v-card-text>
      <v-row>
        <v-col class="d-flex justify-space-between align-center">
          <div style="max-width: 80%">
            <span
              :class="'subtitle-2 ' + (darkTheme ? 'text-white' : 'text-black')"
            >
              {{ info.Opportunities.Name }}
            </span>
          </div>
          <div>
            <v-icon v-if="info.Mustwin" :color="darkTheme ? '' : 'warning'">
              mdi-trophy
            </v-icon>
            <v-icon :color="darkTheme ? '' : getIcon.color">
              {{ getIcon.icon }}
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <span class="actName">{{ info.CompanyClients.Name }}</span>
      <br />
      <span class="actInfo">{{ info.ActivityTypes.Name }}</span>
      <br />
      <span class="actInfo">{{ info.Activities.Objective }}</span>
      <!-- {{ JSON.stringify(info, null, 2) }} -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    info: { type: Object, required: true }
  },
  methods: {
    sendClose() {
      this.$emit('cardClose')
    }
  },
  computed: {
    ...mapState('account', ['darkTheme']),
    getIcon() {
      let today = new Date()
      let actDate = new Date(this.info.Activities.Datetime)
      let icon = { icon: '', color: '' }
      if (actDate >= today) {
        icon.icon = 'mdi-check'
        icon.color = this.darkTheme ? '' : 'success'
      } else {
        icon.icon = 'mdi-alert'
        icon.color = this.darkTheme ? '' : 'warning'
      }
      return icon
    }
  }
}
</script>
