<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>¡Hola, {{ userData.Name }}!</h3>
        <p v-if="notificationList">Tienes actividades pendientes</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        v-for="(item, index) in notificationList"
        :key="index"
        v-scrollfadein
      >
        <activity-card :info="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import ActivityCard from '@/components/ActivityCard'

export default {
  components: { ActivityCard },
  data() {
    return {
      notificationList: null
    }
  },
  methods: {
    getNotifications(showOverlay) {
      let headers = {
        user_id: this.userData.UserId
      }

      axios
        .get(this.$endpointsBaseUrl + 'Activities/Get_JoinActiviesByUser', {
          showOverlay: showOverlay,
          headers: headers
        })
        .then((response) => {
          this.notificationList = response.data
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
    }
  },
  computed: {
    ...mapState('account', ['userData'])
  },
  mounted() {
    this.getNotifications(true)
  }
}
</script>
